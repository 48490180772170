import { BsTelephone, BsEnvelopeAt, BsWhatsapp } from "react-icons/bs";

const Contact = ({person}) => {
  return (
    <div>
        <div className="contacts">
          <div className='contacts-1'>
            <p className="subtitle is-5" id="contact-name">{person.name}</p>
            <p><BsTelephone color="green"/> : {person.phone}</p>
            <p><BsWhatsapp color="forestgreen"/> : {person.whatsapp}</p>
            {/* <p><BsEnvelopeAt color="white"/> : {person.email}</p> */}
          </div>
        </div>  
    </div>
  )
}

export default Contact