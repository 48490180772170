

const Photo = ({photo}) => {
    return (
        <div>
            <div className='image is-1by1' id="image-container-1">
                <img src={photo} alt="hi"></img>
            </div>
        </div>
        
    )
}

export default Photo