import LeadershipSlides from "./LeadershipSlides"
import Packages from "./Packages"


const Leadership = ({infos}) => {
    return (
        <div className="box" id="leadership">
            <h2 className="title is-2" id="leadership-title">Leadership and Teambuilding</h2>
            <LeadershipSlides />
            <p id="leadership-para"> {infos.info}</p>
            <Packages />

        </div>
    )
}

export default Leadership