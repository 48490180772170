import { useEffect, useState } from "react"
import Package from "./Package"
import axios from "axios"


const trips = [
    {
        "id": 2,
        "name": "Outward Bound Zimbabwe",
        "city": "Chimanimani",
        "country": "Zimbabwe",
        "description": "A five day Team Building course / Leadership course program designed to instil career-defining leadership/team building skills within an individual and an organisation in the outskirts of the Eastern Highlands terrain, right on the border of Zimbabwe and Mozambique. \r\n\r\nThe courses have experienced guides and trainers with over 20 years of experience and comes with an Outward Bound Zimbabwe certificates, for either Leadership or Team building that is recognised worldwide. \r\n\r\nTransport, accommodation and food is included in the rate.\r\n\r\nActivities:\r\nJacob's Ladder, Absailing, Expedition, Pamper Pole, Swimming",
        "cost": 450,
        "slug": "outward-bound-zimbabwe-chipinge-zimbabwe"
    }
]


const Packages = () => {

    

    const [packages, setPacakges] = useState([])

    useEffect(() => {
        const getPackages = async () => {
            const packagesFromServer = await fetchPackages()
            setPacakges(packagesFromServer)
        }
        getPackages()

    }, []);

    const fetchPackages = async () => {
        // const res = await axios.get("api/teambuilds")
        // const data = await res.data

        // return data
        return trips
    }

    return (
        <div id="packages">
            <h2 className="title is-4" id="packages-title">Available Packages</h2>
            <p className="subtitle is-6" id="find-out">Click to find out more...</p>
            
            {packages.map((location) => (
                <Package 
                    key={location.id}    
                    location={location}/>
            ))}
            
        </div>
    )
}

export default Packages