import { useState, useEffect } from "react"

import EventCard from "./EventCard"
import CustomEvent from "./CustomEvent"
import EventsPics from "./EventsPics"
import Events from "../Home/Events"
import EventA from "../Home/EventA"

const EventsPage = ({infos}) => {

    const [adventures, setAdventures] = useState([])

    useEffect(() => {
        const getAdventures = async () => {
            const adventuresFromServer = await fetchAdventures()
            setAdventures(adventuresFromServer)
        }

        getAdventures();
    })

    const fetchAdventures = async () => {
        // Get from API
        return 0
    }

    // const locations = [
    //     {
    //         id: 0,
    //         city: "Cape Town",
    //         country: "South Africa",
    //         description: "Things ordinary people are impressed by fall into the categories of things that are held together by simple physics (like stones or wood), or by natural growth (figs, vines, olives . . .). Those admired by more advanced minds are held together by a living soul (flocks of sheep, herds of cows). Still more sophisticated people admire what is guided by a rational mind—not the universal mind, but one admired for its technical knowledge, or for some other skill—or just because it happens to own a lot of slaves.",
    //         price: 300,
    //     },
    //     {
    //         id: 1,
    //         city: "Windhoek",
    //         country: "Namibia",
    //         description: "Yes..... Things ordinary people are impressed by fall into the categories of things that are held together by simple physics (like stones or wood), or by natural growth (figs, vines, olives . . .). Those admired by more advanced minds are held together by a living soul (flocks of sheep, herds of cows). Still more sophisticated people admire what is guided by a rational mind—not the universal mind, but one admired for its technical knowledge, or for some other skill—or just because it happens to own a lot of slaves.",
    //         price: 300,
    //     },
    //     {
    //         id: 2,
    //         city: "Nyanga",
    //         country: "Zimbabwe",
    //         description: "Yes..... Things ordinary people are impressed by fall into the categories of things that are held together by simple physics (like stones or wood), or by natural growth (figs, vines, olives . . .). Those admired by more advanced minds are held together by a living soul (flocks of sheep, herds of cows). Still more sophisticated people admire what is guided by a rational mind—not the universal mind, but one admired for its technical knowledge, or for some other skill—or just because it happens to own a lot of slaves.",
    //         price: 300,
    //     }
    // ]

    return (
        <div className="box" id="adventures">
            <h2 className="title is-2" id="adventures-title">Outjoyment Events</h2>
            {/* <EventsPics /> */}
            <p id="adventures-precursor">{infos.info}</p>

            <h2 className="subtitle is-4" id="events-upcoming-title">Stay tuned...</h2>
            {/* <EventA /> */}
            
            {/* <div id="packages">
                <h2 className="title is-4" id="packages-title">Upcoming Events</h2>

                {locations.map((location) => (
                    <EventCard 
                        key={location.id}
                        location={location}/>
                ))}

                <CustomEvent />
            </div> */}

            {/* <h2 className="subtitle is-4" id="events-upcoming-title">Previously...</h2> */}

            
            
        </div>
    )
}

export default EventsPage