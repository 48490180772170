import { Link } from "react-router-dom"
import LeadershipSlides from "../Leadership/LeadershipSlides"
import BookNow from "./BookNow"
import obz_pempa from "../../images/teambuilding/obz/obz_pempa-pole.JPG"

const TeamBuild = ({infos}) => {
    return (
        <div className='box' id='team'>
            <h2 className='title is-3' id='team-title'>Leadership and Teambuilding</h2>
            
            <div className="columns">
                <div className='column is-7'>
                    <p id="team-info"> {infos.info}</p>
                </div>
                <div className='column is-5'>
                    <figure className="image is-5by3" id="previous-trad-pic">
                        <img src={obz_pempa}></img>
                    </figure>
                </div>
            </div>

            <BookNow path_redirect={"leadership-and-team-building"} />            
        </div>
    )
}

export default TeamBuild