import { Link } from "react-router-dom"

const Button = ({text, path_redirect}) => {
    return (
        <div className='book-now'>
            <div className='button' id='btn-book'>
                <Link to={path_redirect}>
                    <a id="btn-book-t">{text}</a>
                </Link>
            </div>
        </div>
    )
}

export default Button