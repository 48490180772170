import React from "react";
import { Link } from "react-router-dom";
import { BsEnvelope } from "react-icons/bs";

const ButtonMailto = ({ mailto }) => {
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            <BsEnvelope color="whitesmoke"/>
        </Link>
    );
};

export default ButtonMailto;