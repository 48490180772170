import SlideShowMain from "./SlideShowMain"
import SocialLinks from "../ContactsPage/SocialLinks";
import Photobooth from "./Photobooth"
import { BsTiktok } from "react-icons/bs";
import { Link } from "react-router-dom"

const Photography = ({infos, infos2}) => {
    
    var booking = "Booking details:\r\n+264813228691 / +264817672811\r\ninfo@outjoyment.com / outjoyment@gmail.com"

    return (
        <div className="box" id="photography">
            <h2 className="title is-2" id="photo-title">360 Photobooth</h2>

            {/* <div className="box" id="photography-pics"> */}
                
            <SlideShowMain />
            <p id="photo-text">{infos2.info}</p>
            {/* </div> */}

            <p id="photo-booking">{booking}</p>

            <SocialLinks />

            {/* <Link to={"https://www.tiktok.com/@outjoyment_"}>
                <div className="button is-fullwidth" id="tiktok-link">
                    <p>Check out our Tiktok </p>
                    <BsTiktok id="social-icon-2"/>
                </div>
            </Link> */}

            {/* <Photobooth photobooth={infos2}/> */}

        </div>
    )
}

export default Photography