import { useState } from "react";
import AdventureContent from "./AdventureContent";

import swak_swako from "../../images/adventures/swakopmund/swak_swakopmund.jpg"
import swak_quad from "../../images/adventures/swakopmund/swak_quadbike.JPG"
import swak_swak from "../../images/adventures/swakopmund/swak_swak.jpg"
import swak_desert from "../../images/adventures/swakopmund/swak_desert.jpeg"
import swak_camel from "../../images/adventures/swakopmund/swak_camel.JPG"
import swak_centre from "../../images/adventures/swakopmund/swak_centre.jpg"
import swak_brauhaus from "../../images/adventures/swakopmund/swak_brauhaus.jpg"


const AdventureCard = ({location}) => {

    const [show, setShow] = useState(false)

    const toggle = () => {
        setShow(!show)
    }

    const imgs = []

    if (location.name === "Swakopmund Coastal Desert"){
        imgs.push(swak_swak)
        imgs.push(swak_swako)
        imgs.push(swak_quad)
        imgs.push(swak_centre)
        imgs.push(swak_camel)
        imgs.push(swak_desert)
        imgs.push(swak_brauhaus)
    }
    

    return (
        <div>
            <div className="card" id="adventure">
                <div id='adventure-clickable' onClick={toggle}>
                    <div className="columns">
                        <div className="column is-7">
                            <h2 className="title is-4" id="adventure-title">{location.name}</h2>
                            <h2 className="subtitle is-5" id="adventure-subtitle">{location.city}, {location.country}</h2>
                        </div>
                        <div className="column is-5">
                            {location.start_date ? 
                            <p className="subtitle is-5" id="adventure-date">{location.start_date} | {location.end_date}</p> :
                            <br/>
                            }
                            
                        </div>
                    </div>
                    
                </div>
                
                {show && 
                <AdventureContent location={location} imgs={imgs}/>}
                
            </div>
        </div>
    )
}

export default AdventureCard