

const AdventureImage = ({photo}) => {
    

    return (
        <div>
            <div className="image is-5by4" id="adventure-photo">
                <img src={photo}></img>
            </div>        
        </div>
    )
}

export default AdventureImage