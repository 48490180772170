import { useEffect, useState } from "react";
import { BsChevronDown, BsChevronUp, BsEnvelope } from "react-icons/bs";
import Contact from "./Contact"
import Socials from "./Socials"
import ContactForm from "./ContactForm"
import axios from "axios";

const peeps = [
    {
        "id": 4,
        "name": "Ellis Tira",
        "email": "info@outjoyment.com",
        "phone": "+263 78 545 1373",
        "whatsapp": "+264 81 767 2811",
        "display": true
    },
    {
        "id": 5,
        "name": "Clinton Ganyo",
        "email": "info@outjoyment.com",
        "phone": "+264 81 322 8691",
        "whatsapp": "+264 81 322 8691",
        "display": true
    },
    {
        "id": 6,
        "name": "Kiri Nyaaz",
        "email": "info@outjoyment.com",
        "phone": "+263 78 295 3606",
        "whatsapp": "+263 78 295 3606",
        "display": true
    },
    // {
    //     "id": 7,
    //     "name": "Munya Walter",
    //     "email": "info@outjoyment.com",
    //     "phone": "+44 73 0926 6602",
    //     "whatsapp": "+44 73 0926 6602",
    //     "display": true
    // },
    // {
    //     "id": 8,
    //     "name": "Vincent Kamcee",
    //     "email": "info@outjoyment.com",
    //     "phone": "+263 77 449 4406",
    //     "whatsapp": "+263 77 449 4406",
    //     "display": true
    // },
    {
        "id": 9,
        "name": "John-Paul",
        "email": "info@outjoyment.com",
        "phone": "+264 81 445 4568",
        "whatsapp": "+264 81 445 4568",
        "display": true
    }
]



const ContactUs = ({onQuery}) => {

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const [show, setShow] = useState(false)
    const [icon, setIcon] = useState(BsChevronDown)
  
    const toggle = () => {
        setShow(!show)
        // setIcon(show===true ? BsChevronDown : BsChevronUp)
    }
  
    const [people, setPeople] = useState([])

    useEffect(() => {
        const getPeople = async () => {
            const peopleFromServer = await fetchPeople()
            setPeople(peopleFromServer)
        }
        getPeople()
    }, []);

    const fetchPeople = async () => {
        // await sleep(1000)
        // const res = await axios.get("api/contacts")
        // const data = await res.data

        // return data
        return peeps
    }

    return (
        <div className="contact-us">
            <div className='box' id="contact-us">
                <h2 className='title is-4' id='contact-us-header'>Get in touch with us</h2>
                <Socials />
                {/* <p id="email-main"><BsEnvelope /> info@outjoyment.com</p> */}
                <div className="columns" id="contacts-columns" >
                    {people.map((person) => (
                        <div className="column">
                            <Contact 
                                key={person.id}
                                person={person}
                            />
                        </div>    
                    ))}
                    
                </div>
                
                
                {/* <div id="form">
                    <div id="form-header-div">
                        <h2 className="button" id='btn-book' onClick={toggle}>
                            Enquire Now  :&#41; 
                            {/* {icon}  }
                        </h2>
                    </div>
                    {show && <ContactForm onQuery={onQuery}/>}
                </div> */}
                
            </div>    
        </div>
    )
}

export default ContactUs