import { BsTiktok, BsYoutube, BsInstagram, BsTwitterX, BsFacebook } from "react-icons/bs"
import { Link } from "react-router-dom"

const SocialLinks = () => {
  return (
    <div className="card" id="social-links">
        <div className="columns" id="cols-socials">
            <div className="column" id="col-social-links">
                <a href="https://www.facebook.com/profile.php?id=61560878914402">
                    <div className="button" id="btn-facebook"><BsFacebook /> Facebook</div>
                </a>
            </div>
            <div className="column" id="col-social-links">
                <a href="https://www.tiktok.com/@outjoyment_">
                    <div className="button is-black" id="btn-tiktok"><BsTiktok />  Tiktok</div>
                </a>
            </div>
            <div className="column" id="col-social-links">
                <a href="https://www.instagram.com/outjoyment">
                    <div className="button " id="btn-instagram"><BsInstagram />  Instagram</div>
                </a>
            </div>
            <div className="column" id="col-social-links">
                <a href="https://www.youtube.com/@Outjoyment_">
                    <div className="button " id="btn-youtube"><BsYoutube />  Youtube</div>
                </a>
            </div>
            {/* <div className="column" id="col-social-links">
                <a>
                    <div className="button is-black" id="btn-twitterX"><BsTwitterX />  </div>
                </a>
            </div> */}
            
        </div>
    </div>
  )
}

export default SocialLinks