
const Footer = () => {
  return (
    <div className="footer" id="footer">
        <div className="content has-text-centered">
            <p>Designed and Powered by </p>
            <h3>8-bit Bandit &copy;</h3>
            <p>Contact us</p>
            {/* <p><a href="#">www.example.com</a> |  */}
            <p>Phone: +263 78 295 3606 || email: tafa97ra@gmail.com</p>
        </div>
    </div>
  )
}

export default Footer
