import { useState, useEffect } from "react"


import trad_sun_001 from "../../images/trad-sun/trad-sun-001.jpg"
import trad_sun_0002 from "../../images/trad/trad_sun_0002.jpeg"
import trad_sun_0004 from "../../images/trad/trad_sun_0004.jpeg"
import trad_sun_0070 from "../../images/trad/trad_sun_0070.png"
import trad_sun_0077 from "../../images/trad/trad_sun_0077.jpeg"
import trad_sun_0100 from "../../images/trad/trad_sun_0100.jpeg"
import trad_sun_0104 from "../../images/trad-potrait/trad_sun_0104.jpeg"
import trad_sun_0113 from "../../images/trad/trad_sun_0113.jpeg"
import trad_sun_0126 from "../../images/trad/trad_sun_0126.png"
import trad_sun_0134 from "../../images/trad/trad_sun_0134.jpeg"
import trad_sun_0136 from "../../images/trad/trad_sun_0136.jpeg"
import trad_sun_0137 from "../../images/trad/trad_sun_0137.png"
import trad_sun_0132 from "../../images/trad/trad_sun_0132.jpeg"
import trad_sun_0003 from "../../images/trad-potrait/trad_sun_0003.jpeg"
import trad_sun_0009 from "../../images/trad/trad_sun_0009.jpeg"
import trad_sun_0020 from "../../images/trad/trad_sun_0020.jpeg"
import trad_sun_0024 from "../../images/trad/trad_sun_0024.jpeg"
import trad_sun_0083 from "../../images/trad/trad_sun_0083.jpeg"
import trad_sun_0029 from "../../images/trad/trad_sun_0029.png"
import trad_sun_0065 from "../../images/trad/trad_sun_0065.jpeg"
import trad_sun_0051 from "../../images/trad/trad_sun_0051.jpeg"

import UpcomingTrad from "../Traditional/UpcomingTrad"
import BookNow from "./BookNow"






const imgs = [
    
    trad_sun_0070, trad_sun_0077, trad_sun_0132, trad_sun_0100, trad_sun_0137, 
    trad_sun_0104, trad_sun_0029, trad_sun_0024, trad_sun_0113, trad_sun_001, 
    trad_sun_0136, trad_sun_0020, trad_sun_0065, trad_sun_0083, trad_sun_0003, 
    trad_sun_0051, trad_sun_0004, trad_sun_0009, trad_sun_0126, trad_sun_0134, 
    trad_sun_0002, 
]

const TradSun = ({infos}) => {
    const events = [
        {
            "id": 1,
            "name": "Harare, September 2024",
            "city": "Harare",
            "country": "Zimbabwe",
            "description": "1st of September, 1st Zim Edition! You don't wanna miss out on this one. \r\n\r\nJoin us as we endulge in the cuisine of our ancestors and reconnect with our roots. \r\n\r\nEnjoy a buffet of a range of Afro-centric dishes :)",
            "cost": 20,
            "hasCouples": true,
            "couplesCost": 30,
            "hasVIP": false,
            "VIPcost": 0,
        },
    ]

    const [currentIndexA, setCurrentIndexA] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndexA === imgs.length - 1){
                setCurrentIndexA(0);
            }
            else {
                setCurrentIndexA(currentIndexA + 1);
            }
        }, 3190)
        return () => {
            clearInterval(intervalId)
        }
    })



    return (
        <div className="box" id="trad-sun-div">
            <h2 className='title is-3' id='team-title'>Traditional Sunday</h2>
            <div className='columns' id='trad-div'>
                <div className='column is-6'>
                    <p id="booth-para">{infos.info}</p>
                    
                </div>
                <div className='column is-6'>
                    <div id="previous-trad-pic-container">
                        <figure className="image is-5by3" id="previous-trad-pic">
                            <img src={imgs[currentIndexA]}></img>
                        </figure>
                    </div>
                </div>
            </div>
            {/* <UpcomingTrad event={events.find(item => item.name === "Harare, September 2024")}/> */}
            <h2 className="subtitle is-5" id="stay-tuned">Stay Tuned...</h2>
            <BookNow path_redirect={"/traditional-sunday"}/>
        </div>
    )
}

export default TradSun