import { useState, useEffect } from "react"

import AdventuresPics from "./AdventuresPics"
import axios from "axios"
import Packages from "./Packages"




const Adventures = ({infos}) => {


    return (
        <div className="box" id="adventures">
            <h2 className="title is-2" id="adventures-title">Adventures</h2>
            <AdventuresPics />
            <p id="adventures-precursor">{infos.info}</p>

            <Packages />
            
            
        </div>
    )
}

export default Adventures