import PhotoGrid from "./PhotoGrid"
import Information from "./Information"


const Welcome = ({welcome}) => {


    return (
        <div className="welcome">
            <div className='box' id='welcome-main'>
                <div className='columns' id='welcome'>
                    <div className='column is-5'>
                        <PhotoGrid />
                    </div>
                    <div className='column is-7'>
                        <Information welcome={welcome}/> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome