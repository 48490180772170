

const PackageDescription = ({location}) => {
    return (
        <div>
            <div className="card-content" id="adventure-main">
                <p>{location.description}</p>   
                {/* <h2 className="title is-5" id="itenerary-title">Activities</h2>
                <p id="itenerary">
                    &#8226; Mountain Climbing <br></br>
                    &#8226; Teambuilding exercises <br></br>
                    &#8226; Hiking <br></br>
                    &#8226; Bonfire  <br></br>

                </p> */}
                <h2>Cost: USD{location.cost}</h2>
                {/* <div className="button" id="btn-book">
                    Enquire Now
                </div> */}
            </div>
        </div>
    )
}

export default PackageDescription