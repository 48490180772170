

import obz_kd_yutes from "../../images/teambuilding/obz/obz_kd-yutes.JPG"
import obz_pempa from "../../images/teambuilding/obz/obz_pempa-pole.JPG"

const LeadershipSlides = () => {
    return (
        <div className="" id="leadership-slides">
            <div className="columns">
                <div className="column is-half">
                    <figure className="image is-5by3" id="leadership-pics">
                        <img src={obz_kd_yutes}></img>
                    </figure>                    
                </div>
                <div className="column is-half">
                    <figure className="image is-5by3" id="leadership-pics">
                        <img src={obz_pempa}></img>
                    </figure>
                </div>
            </div>
            
        </div>
    )
}

export default LeadershipSlides