import globe from "../../images/logo-light-globe.png"
import writing from "../../images/logo-light-writing.png"
import logo from "../../images/logo-light.png"

import { RxHamburgerMenu } from "react-icons/rx";
import { BsTiktok } from "react-icons/bs";
import { Link } from "react-router-dom"
import { useState } from "react";

const Navbar = () => {

    const [isActive, setIsActive] = useState(false);

    const toggleClass = () => {
        setIsActive(!isActive);
    };

    return (
        <div className="header is-centered" id="header">
            <div id="nav">
                {/* <img className='image is-64x64' id="logo-globe" src={globe} alt="Outjoyment"></img> */}
                        <Link to={"/"}>
                            {/* <img className='image' id='logo-write' src={writing} alt="Outjoyment"></img> */}
                            <img className='image' id='logo-write' src={logo} alt="Outjoyment"></img>
                        </Link>
            </div> 
            
            <nav className="navbar" role="navigation" aria-label="main navigation" id="nav-menus">
                <div className="navbar-brand">
                    <a className="navbar-item" href="#">
                        <img src=""/>
                    </a>
                    <a className="navbar-burger" id="burger" onClick={toggleClass}>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div className="navbar-menu" id='nav-menu'> {/* {isActive ? "navbar-menu is-active" : "navbar-menu"}*/}
                    
                    <div className="navbar-start" id="nav-start">
                        <Link to={"/"}>
                            <a id="nav-item">Home</a>
                        </Link>
                        <Link to={"/traditional-sunday"}>
                            <a id="nav-item-hot">Traditional Sunday</a>
                        </Link>
                        <Link to={"/photobooth"}>
                            <a id="nav-item-hot">360&deg; Photobooth</a>
                        </Link>
                        <Link to={"/events"}>
                            <a id="nav-item">Events</a>
                        </Link>
                        <Link to={"/adventures"}>
                            <a id="nav-item">Adventures</a>
                        </Link>
                        <Link to={"/leadership-and-team-building"}>
                            <a id="nav-item">Leadership and Teambuilding</a>
                        </Link>
                        <Link to={"/gallery"}>
                            <a id="nav-item-hot">Gallery</a>
                        </Link>
                        
                        
                        
                    </div>
                    
                </div>
                <div className={isActive ? "navbar-menu is-active" : "navbar-menu"} id="dropdown">
                    <div className="navbar-dropdown">
                        
                        <Link to={"/"}>
                            <a id="nav-item-drp-dwn">Home</a>
                        </Link>
                        <br/>
                        <Link to={"/traditional-sunday"}>
                            <a id="nav-item-drp-dwn-hot">Traditional Sunday</a>
                        </Link>
                        <br/>
                        <Link to={"/photobooth"}>
                            <a id="nav-item-drp-dwn-hot">360&deg; Photobooth</a>
                        </Link>
                        <br/>
                        <Link to={"/events"}>
                            <a id="nav-item-drp-dwn">Events</a>
                        </Link>
                        <br/>
                        <Link to={"/adventures"}>
                            <a id="nav-item-drp-dwn">Adventures</a>
                        </Link>
                        <br/>
                        <Link to={"/leadership-and-team-building"}>
                            <a id="nav-item-drp-dwn">Leadership and Teambuilding</a>
                        </Link>
                        <br/>
                        <Link to={"/gallery"}>
                            <a id="nav-item-drp-dwn-hot">Gallery</a>
                        </Link>
                        <br/>
                        
                        
                    </div>
                    <div className="navbar-end">
                        <Link to={"/contact-us"}>
                            <a href="https://www.tiktok.com/@outjoyment_">
                                <div className="button is-white" id="btn-contacts">Contact Us</div>
                            </a>
                        </Link>
                    </div>
                </div>
            </nav>
            
        </div>
    )
}

export default Navbar;