import SocialLinks from "./SocialLinks"

const ContactsPage = () => {
    return (
        <div className="box" id="contacts-page">
            <h2 className="title is-5" id="socials-title">Our Social Pages...</h2>
            <SocialLinks />
            

            {/* <h2>Meet Our Team</h2> */}
            
            
        </div>
    )
}

export default ContactsPage