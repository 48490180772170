import Welcome from './Welcome';
import Trips from './Trips'
import Events from './Events'
import TeamBuild from './TeamBuild';
import TradSun from './TradSun';
import Booth from './Booth';

const Home = ({infos, infos2, infos3, infos4}) => {

    // var welcome = {};
	
	// for (let i = 0; i < infos.length; i++) {
	// 	if (infos[i].name === "Welcome"){
	// 		welcome = infos[i]
	// 	}

	// }


    return (
        <div>
            <Welcome welcome={infos}/>
		    <TradSun infos={infos4}/>
            <Booth infos={infos3}/>
            {/* <Events /> */}
            <Trips />
            <TeamBuild infos={infos2}/>
            
        </div>
    )
}

export default Home