
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { act, useState } from "react"



const Gallery = () => {
    
    const [data, setData] = useState({img: "", i: 0})

    // const images = [trad_sun_0051.jpeg, trad_sun_0075.jpeg, trad_sun_0027.jpeg, trad_sun_0100.jpeg, trad_sun_0065.jpeg, trad_sun_0096.jpeg, trad_sun_0083.jpeg, trad_sun_0024.jpeg, trad_sun_0077.jpeg, trad_sun_0042.jpeg, trad_sun_0020.jpeg, trad_sun_0134.jpeg, trad_sun_0004.jpeg, trad_sun_0002.jpeg, trad_sun_0132.jpeg, trad_sun_0136.jpeg, trad_sun_0086.jpeg, trad_sun_0131.jpeg, trad_sun_0113.jpeg, trad_sun_0009.jpeg, trad_sun_0109.jpeg, trad_sun_0091.jpeg, trad_sun_0034.jpeg, trad_sun_0064.jpeg, trad_sun_0039.jpeg, trad_sun_0128.jpeg, trad_sun_0059.jpeg, trad_sun_0116.jpeg, trad_sun_0121.jpeg, trad_sun_0078.jpeg, trad_sun_0005.jpeg, trad_sun_0085.jpeg, trad_sun_0074.jpeg, trad_sun_0046.jpeg, trad_sun_0007.jpeg, trad_sun_0036.jpeg, trad_sun_0104.jpeg, trad_sun_0052.jpeg, trad_sun_0019.jpeg, trad_sun_0076.jpeg, trad_sun_0008.jpeg, trad_sun_0032.jpeg, trad_sun_0043.jpeg, trad_sun_0022.jpeg, trad_sun_0003.jpeg, trad_sun_0069.jpeg, trad_sun_0001.jpeg, ]

    // const images = [
    //     "https://picsum.photos/2000/3000",
    //     "https://picsum.photos/3000/3000",
    //     "https://picsum.photos/2100/12S0",
    //     "https://picsum.photos/4000/4500",
    //     "https://picsum.photos/3500/2100",
    //     "https://picsum.photos/1200/3200",
    //     "https://picsum.photos/1300/1800",
    //     "https://picsum.photos/2000/1500",
    // ]

    const images = [
        "https://outjoyment.com/images/ec4da5f74b2ae1ff46ead204b6458cd6.jpeg",
        "https://outjoyment.com/images/18be14d6b9637b5720c710364cd002b2.jpeg", 
        "https://outjoyment.com/images/55118b302bf3e04492af1b360f7548b1.jpeg",
        "https://outjoyment.com/images/342d4f6ae71eed5dfd0535a39d7806ab.jpeg",
        "https://outjoyment.com/images/7a4d2daba25438b6238bd592b2b36240.jpeg", 
        "https://outjoyment.com/images/d26e3a22f2470bb15c19324b748ce83c.jpeg",
        
        "https://outjoyment.com/images/accb1d9fa326260afc9b478191d380b2.jpeg",
        "https://outjoyment.com/images/da44530b235972ba52c7ef1f3b1c060e.jpeg",
        "https://outjoyment.com/images/405218f11ac34ca675b1fc2df3de392a.jpeg",

        "https://outjoyment.com/images/13dc24446e4e0610a6758a1e15f24e84.jpeg", 
        "https://outjoyment.com/images/6272c7cf67ec1a0734c498c5d7a6fe9d.jpeg", 
        "https://outjoyment.com/images/c90ed3c550ab5b6f0bda013c4d4af00b.jpeg", 
        "https://outjoyment.com/images/87c4fb45319b5b22502433f9ffb3345a.jpeg", 
        "https://outjoyment.com/images/e456233d8280b1e7ae738cc1b89af0cd.jpeg", 
        // "https://outjoyment.com/images/b05e0c45382c3c6a4ffa24a7e2335917.jpeg", 
        "https://outjoyment.com/images/482374d84d7539c147363d21fd395f65.jpeg", 
        "https://outjoyment.com/images/2c3818ab2f1b90772dcf5f770cc22067.jpeg", 
        "https://outjoyment.com/images/4a08f4490c6291cd2dfbfab8187fc605.jpeg", 
        "https://outjoyment.com/images/006a0b0aaccd3f458514d11565755dec.jpeg", 
        "https://outjoyment.com/images/e4d9ba0b316c8b0bbf25c1cf8695f6df.jpeg", 
        "https://outjoyment.com/images/e2466d12cd75ca50992f102d4f9dba10.jpeg", 
        "https://outjoyment.com/images/477776663e7e4e47fb6bb6ddf989e7b4.jpeg", 
        "https://outjoyment.com/images/a098919f1d7be2f987cbb9aa2b540a27.jpeg", 
        "https://outjoyment.com/images/3395cbc087a6927abcb2309d03f6ebca.jpeg", 
        "https://outjoyment.com/images/c00b3f478fc4d216403944c8cc34083b.jpeg", 
         
         
         
        "https://outjoyment.com/images/dbb6bbca94e449ee4519fb2ecf8d2dba.jpeg", 
        "https://outjoyment.com/images/401262213a06f03633f2ae4fe9eeb4f8.jpeg", 
        "https://outjoyment.com/images/e2d4d3ec37c4b33f02f8cc4e4fe74217.jpeg", 
        "https://outjoyment.com/images/3bd863f59862b97535ffb8c93298ce30.jpeg", 
        "https://outjoyment.com/images/1f283a884c14dd60926f43ee398f71ce.jpeg", 
         
        "https://outjoyment.com/images/e650c5c924dd5e84764bca61db72dbff.jpeg", 
        "https://outjoyment.com/images/19f51ba3f4cc95d8208cf681b6578131.jpeg", 
        "https://outjoyment.com/images/2a10995fc55f0c39982c5c7a6985af41.jpeg", 
        "https://outjoyment.com/images/0016d413f39b7de0129f411f854db497.jpeg", 
        "https://outjoyment.com/images/1ff9777f45488aa6c3b534f95a38e653.jpeg", 
        "https://outjoyment.com/images/18cd48ea5d36946b53c8d055aa6ac862.jpeg", 
        "https://outjoyment.com/images/3d5ae586cfc56b3bd0f9f20370a8422e.jpeg", 
        "https://outjoyment.com/images/57d9699fbd6183ef010448da761092f8.jpeg", 
        "https://outjoyment.com/images/585318c218ee31f3e805123d72d49abe.jpeg", 
        "https://outjoyment.com/images/d4a55add93d73e0771eff7412448d94e.jpeg", 
        "https://outjoyment.com/images/dab6e08e94b74f86167047e7a6dfbe34.jpeg", 
        "https://outjoyment.com/images/8c2d5dc24aa037a1d6bd6f3115bb739a.jpeg", 
        "https://outjoyment.com/images/93eecab9fbc55ddfd7d8956f7eeeae8a.jpeg", 
        "https://outjoyment.com/images/ef70c96978c6d4b36bb99cdec00d84f9.jpeg", 
        "https://outjoyment.com/images/52f49c65d5319738231c8b4254c077b2.jpeg", 
        "https://outjoyment.com/images/d6d1e199fd7642580fbf2799237d5ca5.jpeg", 
        "https://outjoyment.com/images/f4ef5fda8caefaec3a1cb09048f53f57.jpeg", 
         
         
         
    ]

    const viewImage = (img, i) => {
        setData({img, i})
    }

    const imgAction = (action) => {
        let i = data.i;
        if (action === 'next-img'){
            setData({img: images[i + 1], i: i + 1})
        } 
        if (action === 'prev-img'){
            setData({img: images[i - 1], i: i - 1})
        }
        if (!action){
            setData({img: '', i: 0})
        }
    }

    return (
        <>
            {data.img &&
                <div id="data-img">
                    <button onClick={() => imgAction()} id="data-img-close">X</button>

                    <button onClick={() => imgAction('prev-img')}>Previous</button>
                    
                    <img src={data.img}  id="clicked-image"/>
                    
                    <button onClick={() => imgAction('next-img')}>Next</button>
                </div>

            }
            <div id="masonry">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
                >
                    <Masonry gutter="20px">
                        {images.map((image, i) => (
                            <img
                                key={i}
                                src={image}
                                alt=""
                                id="masonry-image"
                                // onClick={()=> viewImage(image, i)}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </>
    )
}

export default Gallery