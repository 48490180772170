import Gallery from "./Gallery"


const GalleryMain = () => {
    return (
        <div className="box" id="gallery-main">
            <h2 className="title is-2" id="gallery-main-title">Gallery</h2>
            <Gallery />
        </div>
    )
}

export default GalleryMain