import { useState, useEffect } from "react"

import AdventureCard from "./AdventureCard"
import CustomAdventure from "./CustomAdventure"
import AdventuresPics from "./AdventuresPics"
import axios from "axios"


const Advs = [
    {
        "id": 3,
        "name": "Swakopmund Coastal Desert",
        "city": "Swakopmund Coastal Desert",
        "country": "Namibia",
        "description": "A weekend getaway and a smell of the 2024 Spring season at one of the only two coastal deserts on the planet. \r\n\r\n2-nights in Swakop and Walvis including cruise, desert quadbike, camel rides, games night, nightlife and lit beach accommodation. \r\nFood, driver, chef, cleaner and professional photographer also included.\r\n\r\nMaximum 10 enjoyers.\r\n\r\nAdventure Cost: NAD5000 / $USD250",
        "cost": 250,
        "pictureURL": "https://google.com",
        "slug": "swakopmund-coastal-desert-swakopmund-coastal-deser",
        "start_date": "2024-09-13",
        "end_date": "2024-09-15"
    }
]



const Packages = () => {

    const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

    const [adventures, setAdventures] = useState([])

    useEffect(() => {
        const getAdventures = async () => {
            const adventuresFromServer = await fetchAdventures()
            setAdventures(adventuresFromServer)
        }

        getAdventures();
    }, []);

    const fetchAdventures = async () => {
        // await sleep(1000)
        // const res = await axios.get("api/adventures")
        // const data = await res.data
        // return data
        return Advs
    }


    return (
        <div>
            <div id="packages">
                <h2 className="title is-4" id="packages-title">Upcoming Adventures</h2>
                <p className="subtitle is-6" id="find-out">Click to find out more...</p>
                
                {adventures.map((location) => (
                    <AdventureCard 
                        key={location.id}
                        location={location}/>
                ))}

                {/* <CustomAdventure /> */}
            </div>
        </div>
    )
}

export default Packages