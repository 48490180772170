import trad_sun_006 from "../../images/trad-sun/trad-sun-006.jpg"
import trad_sun_pot_001 from "../../images/trad-sun/trad-sun-pot-004.jpg"

import trad_sun_0137 from "../../images/trad/trad_sun_0137.png"
import trad_sun_0083 from "../../images/trad/trad_sun_0083.jpeg"


// import trad_sun_0001 from "../../images/trad/trad_sun_0001.jpeg"

const TraditionalSlides = () => {
    return (
        <div className="" id="leadership-slides">
            <div className="columns">
                <div className="column is-half">
                    <figure className="image is-5by3" id="traditionals-pics">
                        <img src={trad_sun_0137}></img>
                    </figure>                    
                </div>
                <div className="column is-half">
                    <figure className="image is-5by3" id="traditionals-pics">
                        <img src={trad_sun_0083}></img>
                    </figure>
                </div>
            </div>
            
        </div>
    )
}

export default TraditionalSlides