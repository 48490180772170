import Trip from "./Trip"
import BookNow from "./BookNow"
import { Link } from "react-router-dom"

import Packages from "../Adventures/Packages"

const Trips = () => {
    return (
        <div className='trips'>
            <div className='box' id='trips'>
                {/* <p className='title is-3' id='trips-title'>Upcoming Trips...</p>
                <div className='card' id='trip-main'>
                    <div className='columns' id='trip-in'>
                        <div className='column is-10'>
                            <Trip />
                        </div>
                        <div className='column is-2'>
                            <Link to='/adventures'>
                                <BookNow />
                            </Link>
                                
                        </div>
                    </div>  
                </div> */}
                
                <Packages />

            </div>
        </div>
    )
}

export default Trips