import AdventureDescription from "./AdventureDescription"
import AdventureImage from "./AdventureImage"
import photo from "../../images/home/yay-outdoors.jpg"
import { useState, useEffect } from "react"



const AdventureContent = ({location, imgs}) => {

    const [currentIndexA, setCurrentIndexA] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndexA === imgs.length - 1){
                setCurrentIndexA(0);
            }
            else {
                setCurrentIndexA(currentIndexA + 1);
            }
        }, 3690)
        return () => {
            clearInterval(intervalId)
        }
    })


    return (
        <div className="content" id="adventure-content">
            <div className="columns">
                    
                <div className="column is-7" id="col-ad">
                    <AdventureDescription location={location}/>
                </div>
                
                <div className="column is-5" id="col-ad">
                    <AdventureImage photo={imgs[currentIndexA]}/>
                </div>
            </div>
        </div>
    )
}

export default AdventureContent