import { useEffect, useState } from "react"
import Photo from "./Photo"


import host from "../../images/home/host.jpg"
import camel from "../../images/home/camel.JPG"
import desert from "../../images/home/desert.JPG"
import kd from "../../images/home/kd-yutes.JPG"
import lovehre from "../../images/home/lovehre.jpg"
import quad from "../../images/home/quadbikes.JPG"
import tessas from "../../images/home/tessapool.JPG"
import yay from "../../images/home/yay-quad.JPG"

import booth_booth from "../../images/home/booth_booth.jpg"
// import home_posing from "../../images/home/home_posing.JPG"
import home_falls from "../../images/home/home_falls.jpg"
import home_henties from "../../images/home/home_henties.JPG"

import trad_sun_0002 from "../../images/trad/trad_sun_0002.jpeg"
import trad_sun_0004 from "../../images/trad/trad_sun_0004.jpeg"
import trad_sun_0070 from "../../images/trad/trad_sun_0070.png"
import trad_sun_0077 from "../../images/trad/trad_sun_0077.jpeg"
import trad_sun_0100 from "../../images/trad/trad_sun_0100.jpeg"
import trad_sun_0104 from "../../images/trad-potrait/trad_sun_0104.jpeg"
import trad_sun_0113 from "../../images/trad/trad_sun_0113.jpeg"
import trad_sun_0134 from "../../images/trad/trad_sun_0134.jpeg"
import trad_sun_0136 from "../../images/trad/trad_sun_0136.jpeg"
import trad_sun_0132 from "../../images/trad/trad_sun_0132.jpeg"
import trad_sun_0083 from "../../images/trad/trad_sun_0083.jpeg"
import trad_sun_0137 from "../../images/trad/trad_sun_0137.png"
import trad_sun_0126 from "../../images/trad/trad_sun_0126.png"
import trad_sun_0051 from "../../images/trad/trad_sun_0051.jpeg"
import trad_sun_0029 from "../../images/trad/trad_sun_0029.png"


const PhotoGrid = () => {


    const imgs = [
        trad_sun_0126, camel, quad, trad_sun_0132, trad_sun_0029, 
        tessas, trad_sun_0002, host, trad_sun_0100, home_falls,
        home_henties,  trad_sun_0051, lovehre, trad_sun_0083,
        desert, booth_booth, trad_sun_0137, trad_sun_0104, kd, 
        yay, trad_sun_0070, trad_sun_0077, 
    ]

    const [currentIndexA, setCurrentIndexA] = useState(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (currentIndexA === imgs.length - 1){
                setCurrentIndexA(0);
            }
            else {
                setCurrentIndexA(currentIndexA + 1);
            }
        }, 3690)
        return () => {
            clearInterval(intervalId)
        }
    })

    return (
        <div className='photo-grid' id='photo-grid'>
            <div id="photo">
                <Photo photo={imgs[currentIndexA]}/>
            </div>
        </div>
    )
}

export default PhotoGrid