// import yay from "../../images/home/yay-outdoors.jpg"
// import safari from "../../images/home/safari.jpg"

import camel from "../../images/home/camel.JPG"
import bay from "../../images/home/bay.jpg"

const AdventuresPics = () => {
    return (
        <div className="columns" id="adventures-pics">
            <div className="column is-half">
                <figure className="image is-5by3">
                    <img src={camel}></img>   
                </figure>    
            </div>
            <div className="column is-half">
                <figure className="image is-5by3">
                    <img src={bay}></img>   
                </figure>
            </div>
        </div>
    )
}

export default AdventuresPics