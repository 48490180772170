import { useState } from "react"
import PackageContent from "./PackageContent"

import obz_kd_yutes from "../../images/teambuilding/obz/obz_kd-yutes.JPG"
import obz_kd_tessas from "../../images/teambuilding/obz/obz_kd-yutes-tessas.JPG"
import obz_pempa from "../../images/teambuilding/obz/obz_pempa-pole.JPG"
import obz_rapid from "../../images/teambuilding/obz/obz_rapid.jpg"
import obz_tessas from "../../images/teambuilding/obz/obz_tessas-pool.jpg"

const Package = ({location}) => {

    const [show, setShow] = useState(false)

    const toggle = () => {
        setShow(!show)
    }

    const imgs = []

    if (location.name === "Outward Bound Zimbabwe"){
        imgs.push(obz_kd_yutes)
        imgs.push(obz_rapid)
        imgs.push(obz_kd_tessas)
        imgs.push(obz_pempa)
        imgs.push(obz_tessas)

    }
    
    return (
        <div className="card" id="package">
            <div id='package-clickable' onClick={toggle}>
                <h2 className="title is-4" id="package-title">{location.name}</h2>
                <h2 className="subtitle is-5" id="package-subtitle">{location.city}, {location.country}</h2>
            </div>

            {show && 
            <PackageContent location={location} imgs={imgs}/>}
        </div>
    )
}

export default Package
