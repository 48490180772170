import yay from "../../images/home/yay-outdoors.jpg"
import safari from "../../images/home/safari.jpg"

import host from "../../images/home/host.jpg"
import concert from "../../images/home/concert.jpg"
import booth_booth from "../../images/home/booth_booth.jpg"
import home_posing from "../../images/home/home_posing.JPG"
import home_falls from "../../images/home/home_falls.jpg"
import home_henties from "../../images/home/home_henties.JPG"


const SlideShowMain = () => {
    return (
        
            <div className="columns" id="pictures">
                <div className="column is-half">
                    <figure className="image is-5by3" id="photo-pics">
                        <img src={booth_booth}></img>   
                    </figure>
                </div>
                <div className="column is-half">
                    <figure className="image is-5by3" id="photo-picsB">
                        <img src={host}></img>   
                    </figure>
                    
                </div>
            </div>
        
    )
}

export default SlideShowMain