import { BsFacebook, BsInstagram, BsTwitterX, BsTelephone, BsEnvelopeAt, BsLinkedin, BsWhatsapp, BsTiktok, BsYoutube } from "react-icons/bs";
import ButtonMailto from "./ButtonMaitTo";

const Socials = () => {
    return (
        <div>
            <div className='contacts-2'>
                <p><a href="https://www.facebook.com/profile.php?id=61560878914402" id="social-icon"><BsFacebook color="dodgerblue"/></a></p>
                {/* <p><a href="https://twitter.com/" id="social-icon"><BsTwitterX color="whitesmoke" /></a></p> */}
                <p><a href="https://www.instagram.com/outjoyment/" id="social-icon"><BsInstagram color="pink" /></a></p>
                <p><a href="https://www.tiktok.com/@outjoyment_" id="social-icon"><BsTiktok color="dodgerblue"/></a></p>
                <p><a href="https://www.youtube.com/@Outjoyment_" id="social-icon"><BsYoutube color="red" /></a></p>
                <p><a id="social-icon"><ButtonMailto mailto={"mailto:info@outjoyment.com"} /></a></p>
          </div>
        </div>
    )
}

export default Socials