import trad_sun_001 from "../../images/trad-sun/trad-sun-001.jpg"
import booth_booth from "../../images/home/booth_booth.jpg"

import BookNow from "./BookNow"
import SocialLinks from "../ContactsPage/SocialLinks"

const Booth = ({infos}) => {

    var booking = "Booking details:\r\n+264813228691 / +264817672811\r\ninfo@outjoyment.com / outjoyment@gmail.com"

    return (
        <div className="box" id="booth-div">
            <h2 className='title is-3' id='team-title'>360&deg; Photobooth</h2>
            <div className='columns' id='trad-div'>
                <div className='column is-6'>
                    <div id="booth-pic-container">
                        <figure className="image is-5by3" id="previous-trad-pic">
                            <img src={booth_booth}></img>
                        </figure>
                    </div>
                </div>
                <div className='column is-6'>
                    <p id="booth-para">{infos.info}</p>
                    <p id="photo-booking">{booking}</p>
                    
                </div>
            </div>
            <BookNow path_redirect={"/photobooth"}/>
        </div>
    )
}

export default Booth