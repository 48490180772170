import './App.css';
import 'bulma/css/bulma.min.css'

import { 
    BrowserRouter as Router, 
    Routes,
    Route,
 } from "react-router-dom";

 import { useState, useEffect } from 'react';
 import axios from 'axios';


import Header from './components/Generics/Header';
import Navbar from './components/Generics/Navbar';
import Footer from './components/Footer';
import Home from './components/Home/Home';
import ContactUs from './components/ContactUs/ContactUs';
import Adventures from './components/Adventures/Adventures';
import Leadership from './components/Leadership/Leadership';
import Photography from './components/Photography/Photography';
import EventsPage from './components/Events/EventsPage';
import ContactsPage from './components/ContactsPage/ContactsPage';
import TraditionalSunday from './components/Traditional/TraditionalSunday';
import GalleryMain from './components/Gallery/GalleryMain';

import AppData from './components/AppData';

const Infos = [
	{
		"id": 1,
		"name": "Welcome",
		"info": "At Outjoyment, we curate unforgettable experiences tailored just for you. Whether it's captivating events, curated travel packages, or stunning photography, we've got you covered.\r\n\r\nExperience our world of enjoyment, from dazzling shows to dreamy getaways, and picturesque moments frozen in time, Outjoyment is here to make every moment count!\r\n\r\nEnjoy the moment and embrace the joy of experience."
	},
	{
		"id": 2,
		"name": "Adventures",
		"info": "Discover the thrill of exploring the world with Outjoyment! From majestic waterfalls to vast deserts, safaris, to road trips, and wonders that this world has to offer, we're your passport to exhilarating experiences around the globe.\r\n\r\nJoin us as we traverse continents, immersing ourselves in the rich tapestry of cultures, landscapes, and wonders. Indulge your sense of adventure and embark on a journey that will ignite your spirit and leave you with memories to last a lifetime."
	},
	{
		"id": 3,
		"name": "Photography",
		"info": "This is the photography info"
	},
	{
		"id": 4,
		"name": "Photobooth",
		"info": "Explore stunning photography, captivating videography, and our innovative 360 photobooth, bringing memories to life from every angle.\r\n\r\nWhether it's preserving cherished memories or capturing the essence of a special event, we're here to make every moment count.\r\n"
	},
	{
		"id": 5,
		"name": "Events",
		"info": "At Outjoyment, we believe in the magic of bringing people together to celebrate, connect, and create lasting memories. Our events are carefully crafted to ignite joy and excitement, whether you're seeking a night of entertainment, a cultural celebration, or a networking opportunity.\r\n\r\nExplore our lineup of upcoming events, each designed to inspire, delight, and leave you with unforgettable experiences. From intimate gatherings to grand celebrations, there's something for everyone at Outjoyment."
	},
	{
		"id": 6,
		"name": "Leadership",
		"info": "Step into the realm of Outjoyment Leadership and Teambuilding, where individuals transform into visionary leaders and teams unite to achieve greatness.We cater for corporate organisations, schools of all ages and individuals \r\n\r\nOur programs are designed to cultivate leadership skills, foster collaboration, and ignite the spark of innovation. Whether you're a seasoned executive looking to hone your leadership abilities or a team seeking to enhance synergy and effectiveness, we're here to guide you on your journey to success."
	},
	{
		"id": 7,
		"name": "Traditional",
		"info": "WHAT IS THE TRADITIONAL SUNDAY???\r\n\r\nImagine an event where a lavish buffet beckons with an unlimited array of traditional meats and dishes, set amidst an atmosphere pulsating with good vibes. A live band serenades the crowd with captivating tunes, creating a dynamic backdrop\r\n\r\nAttendees are encouraged to bring their own cooler boxes stocked with their preferred beverages, ensuring a personalized touch to the experience. Additionally, the event features interactive stalls from small business owners, showcasing authentic products that add to the festive spirit and provide unique shopping experiences."
	}
]


function App() {



	// const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
	const some = AppData.Infos


	/** Get Infos from server */
	const [infos, setInfos] = useState([])	
	// const [enquiries, setEnquiries] = useState()


	useEffect(() => {
		const getInfos = async () => {
			const infosFromServer = await fetchInfos()
			setInfos(infosFromServer)
		}
		getInfos()		
	
		
	}, []);

	const fetchInfos = async () => {
		return Infos
	}

	/** Information variables */
	var welcome = {};
	var adventures = {};
	var photography = {};
	var teambuild = {};
	var photobooth = {};
	var events = {};
	var gallery = {};
	var traditional = {};
	
	for (let i = 0; i < infos.length; i++) {
		if (infos[i].name === "Welcome"){
			welcome = infos[i]
		}
		if (infos[i].name === "Adventures"){
			adventures = infos[i]
		}
		if (infos[i].name === "Photography"){
			photography = infos[i]
		}
		if (infos[i].name === "Leadership"){
			teambuild = infos[i]
		}
		if (infos[i].name === "Photobooth"){
			photobooth = infos[i]
		}
		if (infos[i].name === "Events"){
			events = infos[i]
		}
		if (infos[i].name === "Gallery"){
			gallery = infos[i]
		}
		if (infos[i].name === "Traditional"){
			traditional = infos[i]
		}				
	}

	const addQuery = async (query) => {
		const res = await axios.post(`api/enquiries`, query)
		// const data = await res.data
	}

  	return (
    	<div className="App">
			<Router>
				{/* <Header /> */}
				<Navbar />
				<div className="container">
					<Routes>
						<Route
							path='/'
							element={<Home 
								infos={welcome} 
								infos2={teambuild}
								infos3={photobooth}
								infos4={traditional}
							/>}
						/>
						<Route
							path='/events'
							element={<EventsPage 
								infos={events}
							/>}
						/>
						<Route
							path='/adventures'
							element={<Adventures 
								infos={adventures}
							/>}
						/>
						<Route
							path='/leadership-and-team-building'
							element={<Leadership 
								infos={teambuild}
							/>}
						/>
						<Route
							path='/photobooth'
							element={<Photography 
								infos={photography} 
								infos2={photobooth}
							/>}
						/>
						<Route 
							path='/contact-us'
							element={<ContactsPage />}
						/>
						<Route
							path='/traditional-sunday'
							element={<TraditionalSunday 
								infos={traditional}
							/>}
						/>
						<Route
							path='gallery'
							element={<GalleryMain />}
						/>
					</Routes>
					<ContactUs onQuery={addQuery}/>
				</div>

				<Footer />
			</Router>		
    	</div>
  	);
}

export default App;


// COLOURS:
// #491F09		Seal Brown
// #996515		Golden Brown
// #FFBD59		Hunyadi Yellow
// #B47618		Tiger's eye
// #FFB833		Xanthous
