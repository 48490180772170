const Information = ({welcome}) => {
    return (
        <div className='information' id='information'>
            <h2 className='title is-4' id='info-title'>Welcome To Outjoyment</h2>
            <div id='info'>
                <p>{welcome.info}</p>
                
            </div>
            
        </div>
    )
}

export default Information